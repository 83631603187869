import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, EventEmitter, forwardRef, Inject, Input, Output } from "@angular/core";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ErrorSeverityLevel, FxpConstants, FxpMessageService, SharedComponentCallBackEventArgs, UserInfoService } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { CacheKeys, Components, FinancialType, LogEventConstants, ProjectClosureModelEventTypes, ProjectClosureSubmitFormData, ProjectUserStatusTypes, SourceConstants } from "../../../../../common/application.constants";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { LaborManagementService } from "../../../../../common/services/labor-management.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../../common/services/store-dispatch.service";
import { IState } from "../../../../../store/reducers";
import { IEngagementDetailsV2, IProjectDetailsV2, ITeamDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResourceRequestResponse } from "../../../../../common/services/contracts/staffing.service.contract";
import { getEntireResourceRequestsStateObject } from "../../../../../store/resource-requests/resource-requests.selector";
import { InvalidateResourceRequests, LoadResourceRequests } from "../../../../../store/resource-requests/resource-requests.action";
import { IResourceRequestsDetailsState } from "../../../../../store/resource-requests/resource-requests.reducer";
import { IAssignmentDetails, ILaborApprovalCountBasedOnAssignments, ILaborDetailsResponse } from "../../../../../common/services/contracts/labor-management.contract";
import { CacheService } from "../../../../../common/services/cache.service";
import { DmError } from "../../../../../common/error.constants";
import { IConcurPendingReportsResponse } from "../../../../../common/services/contracts/concur.contracts";
import { ConcurService } from "../../../../../common/services/concur.service";
import { OneProfileService } from "../../../../../common/services/one-profile.service";
import { getEntireFinancialDetailsV2 } from "../../../../../store/financial-details-v2/financial-details-v2.selector";
import { getEntireManageSuppliers } from "../../../../../store/manage-suppliers/manage-suppliers.selector";
import { IFinancialDetailsV2State } from "../../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IManageSuppliersState } from "../../../../../store/manage-suppliers/manage-suppliers.reducer";
import { getEntireNpcActuals } from "../../../../../store/npc-actuals/npc-actuals.selector";
import { INpcActualsState } from "../../../../../store/npc-actuals/npc-actuals.reducer";
import { NpcActualsService } from "../../../../../common/services/npcActuals.service";
import { ProjectService } from "../../../../../common/services/project.service";
import { INpcResourceActualsViewModel } from "../../../../../common/services/contracts/npc.contract";
import { getEntireActuals } from "../../../../../store/actuals/actuals.selector";
import { IActualsState } from "../../../../../store/actuals/actuals.reducer";
import { IUnitsViewModel } from "../../../../../common/services/contracts/actuals.contracts";
import { IEngagementFinancial, IEngagementFinancialPlans, IFinancial, IFinancialDetail, IProjectFinancial } from "../../../../../components/financial-mgmt/financial.model";
import { FinancialService } from "../../../../../common/services/financial.service";
import { CFPFixedFeeDetails, DecoContract, POCCDetails, PODetails, RatablePartialECIFProjectDetails, RatablePartialECIFProjectRevenueDetails, RatableProjectDetails, RatableProjectRevenueDetails, SubConFFDetails, SubConTMDetails } from "../../../../../common/services/contracts/deco.contract";
import { IPurchaseOrder } from "../../../../../common/services/contracts/po.service.contracts";
import { isUndefined } from "util";
import { InvalidateFinancialDetailsV2, LoadFinancialDetailsV2 } from "../../../../../store/financial-details-v2/financial-details-v2.action";
import { InvalidateManageSuppliers } from "../../../../../store/manage-suppliers/manage-suppliers.action";
import { InvalidateNpcActuals } from "../../../../../store/npc-actuals/npc-actuals.action";
import { ActualsActionTypes, InvalidateActuals } from "../../../../../store/actuals/actuals.action";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { UnitsService } from "../../../../../common/services/unit.service";
import { UnitSubmissionsResponse } from "../../../../../common/services/contracts/unit-service.contracts";
import { UnitSubmissionStatus } from "../../../../../common/application.constants";
import { StaffingService } from "../../../../../common/services/staffing.service";
import { DmDisplayDateOrDashPipe } from "../../../../../common/services/filters/display-date-or-dash.pipe";


const pendingApprovalStatus = [
    "Committed",
    "Assigned",
    "Complete",
    "Closed"
];

@Component({
    selector: "dm-deco-prevalidation",
    templateUrl: "./deco-prevalidation.html"
})
export class DecoPrevalidation extends DmComponentAbstract {
    @Input() public selectedEngagement: IEngagementDetailsV2;
    @Input() public selectedProject: IProjectDetailsV2;
    @Input() public selectedStatusCode: string;
    @Output() public modalCloseEvent = new EventEmitter();
    @Output() public submitEvent = new EventEmitter<FormData>();

    public decoContract: DecoContract;
    private grmRequestList: IResourceRequestResponse;
    private engagementOrProjectId: string;
    private hasPendingExpensesForEngagement: boolean;
    private hasPendingLaborForEngagement: boolean;
    private hasPendingUnits: boolean;
    private actualLabor: number;
    private fteActualLabor: number;
    private subconActualLabor: number;
    private actualExpenses: string;
    private fteActualExpenses: string;
    private subconActualExpenses: string;
    private actualUnits: number;
    private pendingUnits: number;
    private subConFFActuals: number = 0;
    private npmActuals: number = 0;
    private unitsActuals: number;
    private poccDetails: POCCDetails[];
    private ecifTotalFunds: number;
    private ecifConsumedFunds: number;
    private ffPOdetails: PODetails[];
    private laborPOdetails: PODetails[];
    private expensePOdetails: PODetails[];
    private materialPOdetailsForTM: PODetails[];
    private materialPOdetailsForFF: PODetails[];
    private isProjectContext: boolean;
    private hasFFprojects: boolean;
    private hasAnyPOCCprojects: boolean = false;
    private hasAnyECIFProjects: boolean;
    private hasAnyRARProjects: boolean = false;
    private hasAnyECIFRARProjects: boolean = false;
    private stateFrom: string;
    private stateTo: string;
    private projects: IProjectDetailsV2[];
    private componentinputs: string;
    private FXP_CONSTANTS = FxpConstants;
    private isRefreshClick: boolean = false;
    private laborErrorMessages: string[] = [];
    private cFPECIFErrorMessages: string[] = [];
    private costRecognitationErrorMessages: string[] = [];
    private subconCurrency: string;
    private ecifCurrency: string;
    private cFPFixedFeeCurrency: string;
    private pendingLaborApprovalCount: number = 0;
    private ratableProjectDetails: RatableProjectDetails;
    private ratableECIFProjectDetails: RatablePartialECIFProjectDetails;
    private ratableErrorMessages: string[] = [];


    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(LaborManagementService) private laborManagementService: LaborManagementService,
        @Inject(Store) private store: Store<IState>,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(ConcurService) private concurService: ConcurService,
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(NpcActualsService) private npcActualsService: NpcActualsService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(forwardRef(() => UserInfoService)) public fxpUserInfoService: UserInfoService,
        @Inject(UnitsService) private unitsService: UnitsService,
        @Inject(CurrencyPipe) private currencyPipe: CurrencyPipe,
        @Inject(DecimalPipe) private numberPipe: DecimalPipe,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(DmDisplayDateOrDashPipe) private dmDisplayDateOrDashPipe: DmDisplayDateOrDashPipe,

    ) {
        super(dmLogger, Components.EngagementDecoPrevalidation);
        this.ffPOdetails = [];
        this.laborPOdetails = [];
        this.expensePOdetails = [];
        this.materialPOdetailsForFF = [];
        this.materialPOdetailsForTM = [];
        this.actualLabor = 0;
        this.poccDetails = [];
    }


    public async ngOnInit(): Promise<void> {
        this.getDecoContractValue();
    }

    public onDecoComponentCallback($eventArgs: SharedComponentCallBackEventArgs): void {
        const payload = $eventArgs.detail;
        if (payload.eventName === ProjectClosureModelEventTypes.OnCancel) {
            this.modalCloseEvent.emit();
        }
        else if (payload.eventName === ProjectClosureModelEventTypes.OnSubmit) {
            const formData = new FormData();
            formData.append(ProjectClosureSubmitFormData.File, payload.data[2]);
            formData.append(ProjectClosureSubmitFormData.PJCStatusContract, JSON.stringify(payload.data[0]));
            this.submitEvent.emit(formData);
        }
        else if (payload.eventName === ProjectClosureModelEventTypes.OnRefresh) {
            this.isRefreshClick = true;
            this.laborErrorMessages = [];
            this.costRecognitationErrorMessages = [];
            this.cFPECIFErrorMessages = [];
            this.ratableErrorMessages = [];
            this.store.dispatch(new InvalidateFinancialDetailsV2(this.engagementOrProjectId));
            this.store.dispatch(new InvalidateManageSuppliers(this.engagementOrProjectId));
            this.store.dispatch(new InvalidateNpcActuals(this.engagementOrProjectId));
            this.store.dispatch(new InvalidateActuals(this.engagementOrProjectId));
            this.store.dispatch(new InvalidateResourceRequests(this.engagementOrProjectId));
            this.startLoader();
            this.getDecoContractValue();

            setTimeout(() => {
                this.isRefreshClick = false;
                this.endLoader();
            }, 1000);
        }
    }


    private async getDecoContractValue(): Promise<void> {
        if (!this.isRefreshClick) {
            this.startLoader();
        }
        if (this.selectedEngagement) {
            this.engagementOrProjectId = this.selectedEngagement.id;
            this.hasFFprojects = this.selectedEngagement.projects.filter((p) => p.contractType === "FF" && ! p.userStatusCode.toLocaleUpperCase().includes("MDL")).length > 0;
            this.isProjectContext = false;
            this.hasAnyECIFProjects = this.isAnyProjectECIF(this.selectedEngagement.projects);
            this.stateFrom = this.selectedEngagement.statusCode;
            this.stateTo = this.selectedStatusCode;
            this.projects = this.selectedEngagement.projects;

        }

        else if (this.selectedProject) {
            this.engagementOrProjectId = this.selectedProject.id;
            this.hasFFprojects = this.selectedProject.contractType === "FF";
            this.isProjectContext = true;
            this.hasAnyECIFProjects = this.isAnyProjectECIF([this.selectedProject]);
            this.stateFrom = this.selectedProject.statusCode;
            this.stateTo = this.selectedStatusCode;
            this.projects = [this.selectedProject];
        }

        const rarProjects = this.projects.filter((p) => p.userStatusCode.toLocaleUpperCase().includes("RAR")
            && !p.userStatusCode.toLocaleUpperCase().includes("MDL"));

        if (rarProjects && rarProjects.length > 0){
            this.hasAnyRARProjects = true;
            this.hasAnyECIFRARProjects = this.isAnyProjectRatableECIF(this.projects);
            await this.getRatableProjectDetails();
        }


        await this.getPendingExpensesAndLabor();

        

        const poccProjects = this.projects.filter((p) => p.contractType === "FF" 
        && ! p.userStatusCode.toLocaleUpperCase().includes("MDL")
        && p.userStatusCode.toLocaleUpperCase().includes("FIF")
        && ! p.userStatusCode.toLocaleUpperCase().includes("RAR"));

        if (poccProjects && poccProjects.length > 0 ) {
            this.hasAnyPOCCprojects = true;
            poccProjects.forEach((project) => {
                const projectFinancial$ = this.store.select(getEntireFinancialDetailsV2(project.id));
                projectFinancial$.pipe(untilDestroyed(this))
                    .subscribe((projectFinancialDetailsResponse: IFinancialDetailsV2State) => {
                        if (!projectFinancialDetailsResponse.loaded && !projectFinancialDetailsResponse.loading) {
                            this.store.dispatch(new LoadFinancialDetailsV2(project.id));
                        }
                        if (projectFinancialDetailsResponse.loaded) {
                            const engagementFinancials = projectFinancialDetailsResponse.financialDetails;
                            const currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.CurrentFinancialPlan);
                            const actualFinanceDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.ACTUALS);
                            
                            const contractBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.ContractBaseline);
                            const etcDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.ETC);

                            const plannedCost = currentBaseLineDetails.cost;
                            const actualCost = actualFinanceDetails ? actualFinanceDetails.cost : 0;
                            const pocc = this.getPOCC(actualCost, plannedCost);
                            const contractRevenue = contractBaseLineDetails.revenue;
                            const recognizedRevenue = actualFinanceDetails ? actualFinanceDetails.revenue : 0;
                            const etc = etcDetails ? etcDetails.cost : 0;
                            const remainingRiskReserve = currentBaseLineDetails ? currentBaseLineDetails.riskReserveAmount : 0;
                            this.cFPFixedFeeCurrency = contractBaseLineDetails.planCurrency;
                    
                            this.poccDetails.push({
                                projectID: project.id,
                                contractRevenue,
                                recognizedRevenue,
                                pOCC: pocc,
                                etc,
                                remainingRiskReserve
                            });
                        }
                    });
            });
        }

        this.storeDispatchService
            .requireFinancialDetailsV2(this.engagementOrProjectId, true)
            .requireManageSuppliers(this.engagementOrProjectId, true)
            .requireNpcActuals(this.engagementOrProjectId, true)
            .requireActuals(this.engagementOrProjectId, true)
            .load();

        const financialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.engagementOrProjectId));
        const manageSuppliers$ = this.store.select(getEntireManageSuppliers(this.engagementOrProjectId));
        const npcActuals$ = this.store.select(getEntireNpcActuals(this.engagementOrProjectId));
        const unitActuals$ = this.store.select(getEntireActuals(this.engagementOrProjectId));

        observableCombineLatest(
            financialDetails$,
            manageSuppliers$,
            npcActuals$,
            unitActuals$,

            (
                financialDetails: IFinancialDetailsV2State,
                manageSuppliers: IManageSuppliersState,
                npcActuals: INpcActualsState,
                unitActuals: IActualsState,

            ) => {
                return ({
                    financialDetails,
                    manageSuppliers,
                    npcActuals,
                    unitActuals,
                });
            }
        ).pipe(untilDestroyed(this))
            .subscribe(async ({
                financialDetails,
                manageSuppliers,
                npcActuals,
                unitActuals
            }) => {
                if (financialDetails && financialDetails.loaded && manageSuppliers && manageSuppliers.loaded && npcActuals && npcActuals.loaded &&
                    unitActuals && unitActuals.loaded) {
                    this.setErrorsBasedOnItemState(financialDetails, manageSuppliers, npcActuals, unitActuals);

                    const actualDetails = this.financialService.getFinancialDetailsFromParentForV2Object(financialDetails.financialDetails, FinancialType.ACTUALS);
                    this.actualLabor = actualDetails ? actualDetails.labor : 0;
                    this.fteActualLabor = actualDetails ? actualDetails.laborFTE : 0;
                    this.subconActualLabor = actualDetails ? actualDetails.laborSubCon : 0;
                    const actualLaborCurrency = actualDetails ? actualDetails.planCurrency : undefined;

                    if (isUndefined(this.actualLabor)) {
                        const errorMessage = "Error in retrieving actual labor value";
                        const value = this.laborErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                        if (isUndefined(value)) {
                            this.laborErrorMessages.push(errorMessage);
                        }
                    }

                    this.npcActualsService.formatNpcActualsViewModel(npcActuals.npcActuals);
                    const originalResourceActuals: INpcResourceActualsViewModel[] = this.npcActualsService.originalResourceActuals;
                    if (originalResourceActuals && originalResourceActuals.length > 0) {
                        this.npmActuals = originalResourceActuals.reduce((actuals, originalResourceActual) => actuals + originalResourceActual.actualQuantity, 0);
                    }
                    if (npcActuals.error) {
                        this.npmActuals = undefined;
                        const errorMessage = "Error in retrieving npm actuals value";
                        const value = this.costRecognitationErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                        if (isUndefined(value)) {
                            this.costRecognitationErrorMessages.push(errorMessage);
                        }
                    }

                    this.getPODetails(manageSuppliers.manageSuppliers);
                    if (this.ffPOdetails && this.ffPOdetails.length > 0) {
                        this.subConFFActuals = this.ffPOdetails.reduce((actuals, poDetails) => actuals + poDetails.pOAmount, 0);
                    }
                    if (manageSuppliers.error) {
                        const errorMessage = "Error in retrieving subconFF value";
                        const value = this.costRecognitationErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                        if (isUndefined(value)) {
                            this.costRecognitationErrorMessages.push(errorMessage);
                        }
                    }

                    this.unitsActuals = 0;
                    const unitsViewModelList: IUnitsViewModel[] = unitActuals.units;
                    if (unitsViewModelList && unitsViewModelList.length) {
                        for (const project of unitsViewModelList) {
                            for (const task of project.tasks) {
                                for (const unit of task.demands) {
                                    this.unitsActuals = this.unitsActuals + unit.actualQuantity;
                                }
                            }
                        }
                    }

                    if (unitActuals.error) {
                        this.unitsActuals = undefined;
                        const errorMessage = "Error in retrieving units Value";
                        const value = this.costRecognitationErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                        if (isUndefined(value)) {
                            this.costRecognitationErrorMessages.push(errorMessage);
                        }
                    }

                    await this.getActualExpenseDetails();

                    if (this.hasAnyECIFProjects) {
                        await this.getEcifIoConsumption();
                    }

                    const subconFFDetails: SubConFFDetails = {
                        pOAmount: this.transform(this.ffPOdetails.reduce((poAmount, poDetails) => poAmount + poDetails.pOAmount, 0), this.subconCurrency),
                        gRAmount: this.transform(this.ffPOdetails.reduce((grAmount, poDetails) => grAmount + poDetails.gRAmount, 0), this.subconCurrency),
                        invoicedAmount: this.transform(this.ffPOdetails.reduce((irAmount, poDetails) => irAmount + poDetails.invoicedAmount, 0), this.subconCurrency),
                        fixedFeePurchaseOrders: this.ffPOdetails,
                        errors: manageSuppliers.error ? ["Error in retrieving subconFF details"] : []
                    };

                    const allPoDetails = this.laborPOdetails.concat(this.expensePOdetails, this.materialPOdetailsForTM);
                    const subconTMDetails: SubConTMDetails = {
                        pOAmount: this.transform(allPoDetails.reduce((poAmount, poDetails) => poAmount + poDetails.pOAmount, 0), this.subconCurrency),
                        gRAmount: this.transform(allPoDetails.reduce((grAmount, poDetails) => grAmount + poDetails.gRAmount, 0), this.subconCurrency),
                        invoicedAmount: this.transform(allPoDetails.reduce((irAmount, poDetails) => irAmount + poDetails.invoicedAmount, 0), this.subconCurrency),
                        laborPurchaseOrders: this.laborPOdetails,
                        expensePurchaseOrders: this.expensePOdetails,
                        materialPurchaseOrders: this.materialPOdetailsForTM,
                        errors: manageSuppliers.error ? ["Error in retrieving subconFF details"] : []
                    };


                    const allMaterialPoDetails = this.materialPOdetailsForFF.concat(this.materialPOdetailsForTM);

                    const cFPFixedFeeDetails: CFPFixedFeeDetails = {
                        contractRevenue: this.transform(this.poccDetails.reduce((crRevenue, pocc) => crRevenue + pocc.contractRevenue, 0), this.cFPFixedFeeCurrency),
                        recognizedRevenue: this.transform(this.poccDetails.reduce((recRevenue, pocc) => recRevenue + pocc.recognizedRevenue, 0), this.cFPFixedFeeCurrency),
                        pocc: this.transform((this.poccDetails.reduce((pocc, poccDetail) => pocc + poccDetail.pOCC, 0)) / this.poccDetails.length),
                        ffProjectDetails: this.poccDetails,
                        errors: isUndefined(this.poccDetails) ? ["Error while retrieving the values of CFPFixedFeeDetails"] : []
                    };

                    if (manageSuppliers.error) {
                        const errorMessage = "Error in retrieving material details";
                        const value = this.costRecognitationErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                        if (isUndefined(value)) {
                            this.costRecognitationErrorMessages.push(errorMessage);
                        }
                    }

                    this.decoContract = {
                        wbsId: this.engagementOrProjectId,
                        wbsType: this.isProjectContext ? this.selectedProject.contractType : this.selectedEngagement.typeOfContract,
                        stateFrom: this.stateFrom,
                        stateTo: this.stateTo,
                        wbsStartDate: this.isProjectContext ? this.selectedProject.startDate : this.selectedEngagement.startDate,
                        wbsEndDate: this.isProjectContext ? this.selectedProject.endDate : this.selectedEngagement.endDate,
                        laborExpenseDetails: {
                            labor: !isUndefined(this.actualLabor) ? this.transform(this.actualLabor, actualLaborCurrency) : "",
                            fteLabor: !isUndefined(this.fteActualLabor) ? this.transform(this.fteActualLabor, actualLaborCurrency) : "",
                            subconLabor: !isUndefined(this.subconActualLabor) ? this.transform(this.subconActualLabor, actualLaborCurrency) : "",
                            expenses: this.actualExpenses,
                            fteExpenses: this.fteActualExpenses,
                            subconExpenses: this.subconActualExpenses,
                            hasPendingLabor: this.hasPendingLaborForEngagement,
                            hasPendingExpense: this.hasPendingExpensesForEngagement,
                            units: !isUndefined(this.unitsActuals) ? this.transform(this.unitsActuals) : "",
                            hasPendingUnits: this.hasPendingUnits,
                            errors: this.laborErrorMessages
                        },
                        subConFFDetails: subconFFDetails,
                        subConTMDetails: subconTMDetails,
                        cFPFixedFeeDetails,
                        cFPECIFDetails: {
                            eCIFBudget: this.transform(this.ecifTotalFunds, this.ecifCurrency),
                            eCIFConsumed: this.transform(this.ecifConsumedFunds, this.ecifCurrency),
                            errors: this.cFPECIFErrorMessages
                        },
                        costRecognitationDetails: {
                            subconFF: this.hasFFprojects ? this.transform(this.subConFFActuals, this.subconCurrency) : "0.00",
                            materials: this.transform(allMaterialPoDetails.reduce((grAmount, poDetails) => grAmount + poDetails.gRAmount, 0), this.subconCurrency),
                            nPM: this.transform(this.npmActuals),
                            units: this.transform(this.unitsActuals),
                            errors: this.costRecognitationErrorMessages
                        },
                        ratableProjectDetails: this.ratableProjectDetails,
                        ratablePartialECIFProjectDetails: this.ratableECIFProjectDetails,
                        isAnyProjectECIF: this.hasAnyECIFProjects,
                        isEngagementSelected: !this.isProjectContext,
                        hasAnyPOCCprojects: this.hasAnyPOCCprojects,
                        hasAnyRARProjects: this.hasAnyRARProjects,
                        hasAnyRARPartialECIFProjects: this.hasAnyECIFRARProjects,
                        includeFFRules: this.projects.filter((p) => p.contractType === "FF" && !p.userStatusCode.toLocaleUpperCase().includes("MDL") && !p.userStatusCode.toLocaleUpperCase().includes("RAR")).length > 0

                    };
                    this.componentinputs = JSON.stringify({ input: this.decoContract });

                    if (!this.isRefreshClick) {
                        this.endLoader();
                    }
                }

            });
    }


    /**
     * Checks all Projects and returns true if any one is ECIF
     * @param projects List of Projects
     */
    private isAnyProjectECIF(projects: IProjectDetailsV2[]): boolean {
        let isProjectECIF = false;

        projects.forEach((project) => {
            if (project && !project.userStatusCode.toLocaleUpperCase().includes("MDL") && !project.userStatusCode.toLocaleUpperCase().includes("RAR")) {
                isProjectECIF = isProjectECIF || this.sharedFunctionsService.isProjectECIF(project.userStatusCode) ||
                    project.services.some((service) => { return this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.ECIFPartial; });
            }
        });
        return isProjectECIF;

    }

    /**
     * Checks all Projects and returns true if any one is ECIF
     * @param projects List of Projects
     */
    private isAnyProjectRatableECIF(projects: IProjectDetailsV2[]): boolean {
        let isProjectRatableECIF = false;

        projects.forEach((project) => {
            if (project && !project.userStatusCode.toLocaleUpperCase().includes("MDL") && project.userStatusCode.toLocaleUpperCase().includes("RAR")) {
                isProjectRatableECIF = isProjectRatableECIF ||
                    project.services.some((service) => { return service.userStatusCode.toLocaleUpperCase().includes("ECP"); });
            }
        });
        return isProjectRatableECIF;

    }

    private getPODetails(purchaseOrders: IPurchaseOrder[]) {
        if (purchaseOrders && purchaseOrders.length > 0) {

            for (const po of purchaseOrders) {
                const ffLineitems = po.lineItems.filter((lineItem) => lineItem.accountAssignmentCategory.toLowerCase() === "p");
                const laborLineItems = po.lineItems.filter((lineItem) => lineItem.accountAssignmentCategory.toLowerCase() === "u" && lineItem.category === "9");
                const expenseLineItems = po.lineItems.filter((lineItem) => lineItem.accountAssignmentCategory.toLowerCase() === "z" && lineItem.category === "1");
                const ffMaterialLineitems = po.lineItems.filter((lineItem) => lineItem.accountAssignmentCategory.toLowerCase() === "p" && lineItem.unitOfMeasure.toLowerCase() === "ea");
                const tmMaterialLineitems = po.lineItems.filter((lineItem) => lineItem.accountAssignmentCategory.toLowerCase() === "u" && lineItem.unitOfMeasure.toLowerCase() === "ea" && lineItem.category === "9");
                this.subconCurrency = po.currency;

                if (ffLineitems && ffLineitems.length > 0) {
                    const poDetails: PODetails = {
                        pONumber: po.poNumber,
                        pOAmount: ffLineitems.reduce((actuals, ffLineitem) => actuals + ffLineitem.orderedAmount, 0),
                        gRAmount: ffLineitems.reduce((actuals, ffLineitem) => actuals + ffLineitem.costComplete, 0),
                        invoicedAmount: ffLineitems.reduce((actuals, ffLineitem) => actuals + ffLineitem.amountInvoiced, 0),
                    };
                    this.ffPOdetails.push(poDetails);
                }

                if (laborLineItems && laborLineItems.length > 0) {
                    const poDetails: PODetails = {
                        pONumber: po.poNumber,
                        pOAmount: laborLineItems.reduce((actuals, ffLineitem) => actuals + ffLineitem.orderedAmount, 0),
                        gRAmount: laborLineItems.reduce((actuals, ffLineitem) => actuals + ffLineitem.costComplete, 0),
                        invoicedAmount: laborLineItems.reduce((actuals, ffLineitem) => actuals + ffLineitem.amountInvoiced, 0)
                    };
                    this.laborPOdetails.push(poDetails);
                }

                if (expenseLineItems && expenseLineItems.length > 0) {
                    const poDetails: PODetails = {
                        pONumber: po.poNumber,
                        pOAmount: expenseLineItems.reduce((actuals, ffLineitem) => actuals + ffLineitem.orderedAmount, 0),
                        gRAmount: expenseLineItems.reduce((actuals, ffLineitem) => actuals + ffLineitem.costComplete, 0),
                        invoicedAmount: expenseLineItems.reduce((actuals, ffLineitem) => actuals + ffLineitem.amountInvoiced, 0)
                    };
                    this.expensePOdetails.push(poDetails);
                }

                if (tmMaterialLineitems && tmMaterialLineitems.length > 0) {
                    const poDetails: PODetails = {
                        pONumber: po.poNumber,
                        pOAmount: tmMaterialLineitems.reduce((actuals, tmLineitem) => actuals + tmLineitem.orderedAmount, 0),
                        gRAmount: tmMaterialLineitems.reduce((actuals, tmLineitem) => actuals + tmLineitem.costComplete, 0),
                        invoicedAmount: tmMaterialLineitems.reduce((actuals, tmLineitem) => actuals + tmLineitem.amountInvoiced, 0)
                    };
                    this.materialPOdetailsForTM.push(poDetails);
                }

                if (ffMaterialLineitems && ffMaterialLineitems.length > 0) {
                    const poDetails: PODetails = {
                        pONumber: po.poNumber,
                        pOAmount: ffMaterialLineitems.reduce((actuals, ffLineitem) => actuals + ffLineitem.orderedAmount, 0),
                        gRAmount: ffMaterialLineitems.reduce((actuals, ffLineitem) => actuals + ffLineitem.costComplete, 0),
                        invoicedAmount: ffMaterialLineitems.reduce((actuals, ffLineitem) => actuals + ffLineitem.amountInvoiced, 0)
                    };
                    this.materialPOdetailsForFF.push(poDetails);
                }


            }
        }


    }

    /**
     * Gets Actual Expense Details
     */

    private async getActualExpenseDetails() {
        this.actualExpenses = "0";
        this.fteActualExpenses = "0";
        this.subconActualExpenses = "0";

        let actualExpenseAmount = 0;
        let fteactualExpenseAmount = 0;
        let subconactualExpenseAmount = 0;
        await this.projectService.getExpenseDetails(this.engagementOrProjectId).then((response) => {
            if (response && response.length > 0 && response[0].expenseDetails && response[0].expenseDetails.length > 0) {
                actualExpenseAmount = response[0].expenseDetails.reduce((expsnesActualAmount, expense) => expsnesActualAmount + expense.expenseAmount, 0);
                fteactualExpenseAmount = response[0].expenseDetails.reduce((fteexpsnesActualAmount, expense) => fteexpsnesActualAmount + expense.fteExpenseAmount, 0);
                subconactualExpenseAmount = response[0].expenseDetails.reduce((subconexpsnesActualAmount, expense) => subconexpsnesActualAmount + expense.subconExpenseAmount, 0);
                
                this.actualExpenses = this.transform(actualExpenseAmount, response[0].expenseDetails[0].currency);
                this.fteActualExpenses = this.transform(fteactualExpenseAmount, response[0].expenseDetails[0].currency);
                this.subconActualExpenses = this.transform(subconactualExpenseAmount, response[0].expenseDetails[0].currency);
                
            }

        }).catch(() => {
            this.actualExpenses = undefined;
            this.subconActualExpenses = undefined;
            this.fteActualExpenses = undefined;
            const errorMessage = "Error in retrieving actual expenses value";
            const value = this.laborErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
            if (isUndefined(value)) {
                this.laborErrorMessages.push(errorMessage);
            }
        });

    }

    /**
     * Gets Ratable Project Details
     */

    private async getRatableProjectDetails() {
        await this.projectService.getRatableProjectDetails(this.engagementOrProjectId).then((response) => {
            if (response && response.ratableProjectDetails && response.ratableProjectDetails.length > 0) {
                const ratableProjects = response.ratableProjectDetails.filter((project) => project.revType.toUpperCase() !== "PARTIAL ECIF");
                const ratableECIFProjects = response.ratableProjectDetails.filter((project) => project.revType.toUpperCase() === "PARTIAL ECIF");
                const ratableProjectRevDetails: RatableProjectRevenueDetails[] = [];
                const ratableECIFProjectRevDetails: RatablePartialECIFProjectRevenueDetails[] = [];
                
                if (ratableProjects && ratableProjects.length > 0){
                    ratableProjects.forEach((ratableproject) => {
                        const ratableProject: RatableProjectRevenueDetails = {
                            projectID: ratableproject.wbsId,
                            ContractEndDate: this.dmDisplayDateOrDashPipe.transform(ratableproject.projectEndDate),
                            CurrentDate: this.dmDisplayDateOrDashPipe.transform(ratableproject.currentDate),
                            contractRevenue: this.transform(ratableproject.plannedRevenue, ratableproject.currency),
                            recognizedRevenue: this.transform(ratableproject.actualRevenue, ratableproject.currency),
                            recognizedRevenuePercentage: ratableproject.revenueRecognizedPercentage,
                            Currency: ratableproject.currency
                        };
                        ratableProjectRevDetails.push(ratableProject);
                        
                    });

                    this.ratableProjectDetails = {
                        ContractEndDate: this.dmDisplayDateOrDashPipe.transform(response.projectEndDate),
                        CurrentDate: this.dmDisplayDateOrDashPipe.transform(response.currentDate),
                        ContractEndDateVal: response.projectEndDate,
                        CurrentDateVal: response.currentDate,
                        contractRevenue: this.transform(ratableProjects.reduce((revenue, ratableproj) => revenue + ratableproj.plannedRevenue, 0), ratableProjects[0].currency),
                        recognizedRevenue: this.transform(ratableProjects.reduce((revenue, ratableproj) => revenue + ratableproj.actualRevenue, 0), ratableProjects[0].currency),
                        // recognizedRevenuePercentage: ratableProjects.reduce((revenue, ratableproj) => revenue + ratableproj.revenueRecognizedPercentage, 0) / ratableProjects.length,
                        ratableProjectDetails: ratableProjectRevDetails,
                        recognizedRevenueVal: ratableProjects.reduce((revenue, ratableproj) => revenue + ratableproj.actualRevenue, 0),
                        errors: this.ratableErrorMessages

                    };
                    

                }
                if (ratableECIFProjects && ratableECIFProjects.length > 0) {
                    ratableECIFProjects.forEach((ratableECIFproject) => {
                        const ratableECIFProject: RatablePartialECIFProjectRevenueDetails = {
                            serviceID: ratableECIFproject.wbsId,
                            ContractEndDate: this.dmDisplayDateOrDashPipe.transform(ratableECIFproject.projectEndDate),
                            CurrentDate: this.dmDisplayDateOrDashPipe.transform(ratableECIFproject.currentDate),
                            contractRevenue: this.transform(ratableECIFproject.plannedRevenue, ratableECIFproject.currency),
                            recognizedRevenue: this.transform(ratableECIFproject.actualRevenue, ratableECIFproject.currency),
                            recognizedRevenuePercentage: ratableECIFproject.revenueRecognizedPercentage,
                            ConsumedFund: this.transform(ratableECIFproject.consumedFund, ratableECIFproject.currency),
                            PlannedFund: this.transform(ratableECIFproject.plannedFund, ratableECIFproject.currency),
                            Currency: ratableECIFproject.currency,
                            IONumber: ratableECIFproject.internalOrderNumber,
                            IOStatus: ratableECIFproject.ioStatusText,

                        };
                        ratableECIFProjectRevDetails.push(ratableECIFProject);
                    });

                    this.ratableECIFProjectDetails = {
                        ContractEndDate: this.dmDisplayDateOrDashPipe.transform(response.projectEndDate),
                        CurrentDate: this.dmDisplayDateOrDashPipe.transform(response.currentDate),
                        ContractEndDateVal: response.projectEndDate,
                        CurrentDateVal: response.currentDate,
                        contractRevenue: this.transform(ratableECIFProjects.reduce((revenue, ratableproj) => revenue + ratableproj.plannedRevenue, 0), ratableECIFProjects[0].currency),
                        recognizedRevenue: this.transform(ratableECIFProjects.reduce((revenue, ratableproj) => revenue + ratableproj.actualRevenue, 0), ratableECIFProjects[0].currency),
                        // recognizedRevenuePercentage: ratableECIFProjects.reduce((revenue, ratableproj) => revenue + ratableproj.revenueRecognizedPercentage, 0) / ratableProjects.length,
                        ConsumedFund: this.transform(ratableECIFProjects.reduce((consumedFund, ratableproj) => consumedFund + ratableproj.consumedFund, 0), ratableECIFProjects[0].currency),
                        PlannedFund: this.transform(ratableECIFProjects.reduce((plannedFund, ratableproj) => plannedFund + ratableproj.plannedFund, 0), ratableECIFProjects[0].currency),
                        ratablePartialECIFProjectDetails: ratableECIFProjectRevDetails,
                        recognizedRevenueVal: ratableECIFProjects.reduce((revenue, ratableproj) => revenue + ratableproj.actualRevenue, 0),
                        errors: this.ratableErrorMessages

                    };
                }
                

            }

        }).catch(() => {
            const errorMessage = "Error in retrieving ratable project details";
            const value = this.ratableErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
            if (isUndefined(value)) {
                this.ratableErrorMessages.push(errorMessage);
                this.ratableECIFProjectDetails.errors = this.ratableErrorMessages;
                this.ratableProjectDetails.errors = this.ratableErrorMessages;
            }
        });

    }

    /**
     * Gets ECIF IO Consumption details from API
     */
    private async getEcifIoConsumption() {
        this.ecifTotalFunds = 0;
        this.ecifConsumedFunds = 0;

        const projects: IProjectDetailsV2[] = this.isProjectContext ? [this.selectedProject] : this.selectedEngagement.projects;

        const ecifTypeProjects = [];

        projects.forEach((project) => {
            if (!project.userStatusCode.toLocaleUpperCase().includes("MDL")){
                if (this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.ECIF){
                    ecifTypeProjects.push(project.id);

                }
                const ecifPartialServices = project.services.filter((service) => this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.ECIFPartial);

                if (ecifPartialServices && ecifPartialServices.length > 0){
                    ecifPartialServices.forEach((service) => {
                        ecifTypeProjects.push(service.id);
                    });
                }

            }
            
        });       

        await this.projectService.getEcifIoConsumption(this.engagementOrProjectId).then((response) => {
            if (response && response.length) {
                response.forEach((wbs) => {
                    if (ecifTypeProjects.includes(wbs.wbsId)){
                        if (wbs.ioDetails && wbs.ioDetails.length) {
                            wbs.ioDetails.forEach((io) => {
                                
                                this.ecifTotalFunds = this.ecifTotalFunds + io.totalFunds;
                                this.ecifConsumedFunds = this.ecifConsumedFunds + io.consumedFunds;
                                this.ecifCurrency = io.currency;
                            });
                        }

                    }
                    
                });
            }
        }).catch(() => {
            this.ecifTotalFunds = undefined;
            this.ecifConsumedFunds = undefined;
            const errorMessage = "Error in retrieving EcifToConsumption Values";
            const value = this.cFPECIFErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
            if (isUndefined(value)) {
                this.cFPECIFErrorMessages.push(errorMessage);
            }
        });
    }

    /**
     * Calculate POCC percentage
     * @param currentBaseLineDetails 
     * @param actualDetails 
     */
    private getPOCC(actualCost: number, plannedCost: number): number {
        let pocc = 0;
        if (actualCost && plannedCost) {
            pocc = Math.round(actualCost / plannedCost * 10000) / 100;
            pocc = pocc > 100 ? 100 : pocc;
        }
        return pocc > 100 ? 100 : pocc;
    }

    /**
     * Gets a single Financial object from the given engagement financial list with the specific baseline type.
     * @param engagementFinancialList
     * @param baselineType
     */
    private getFinancialsByBaselineType(engagementFinancialList: IEngagementFinancial | IProjectFinancial, baselineType: string): IFinancial {
        const financialDetails: IFinancialDetail[] = this.financialService.getFinancialDetailForBaselineType(engagementFinancialList, baselineType);
        if (this.financialService.financialObjExists(financialDetails)) {
            return financialDetails[0].financials[0];
        }
        return undefined;
    }



    private async getPendingExpensesAndLabor() {

        await this.checkForPendingLabor();

        await this.checkForPendingExpenses();

        await this.getUnitDetails();
        
    }

    private async getUnitDetails() {
        this.hasPendingUnits = false;
        this.pendingUnits = 0;
        this.actualUnits = 0;

        // Make call to get pending units only if selected engagement has any unit based demands else pass this check. 
        if (this.engagementOrProjectId && (this.isProjectContext ? this.selectedProject.hasUnitBasedDemands : this.selectedEngagement.hasUnitBasedDemands)) {

            // To Do: This call was added to show the actual approved units, 
            // we are not sure if business wants it as of now hence commenting out the call for now.
            // Remove this call later if not not needed, post business verification.

            // const actualUnits$ = this.projectService.getActualsData(this.engagementOrProjectId);
            const pendingUnits$ = this.unitsService.getPendingUnitApprovalsForWbsId(this.engagementOrProjectId);

            Promise.all([pendingUnits$])
                .then((result: any[]) => {
                    // const actualSubmissions: IUnitsViewModel[] = result[0];
                    const unitSubmissions: UnitSubmissionsResponse[] = result[0];

                    // if (actualSubmissions && actualSubmissions.length) {
                    //     let actualSubmissionValue = 0;

                    //     actualSubmissions.forEach((project) => {
                    //         project.tasks.forEach((task) => {
                    //             task.demands.forEach((demand) => {
                    //                 actualSubmissionValue += demand.actualQuantity;
                    //             });
                    //         });
                    //     });

                    //     this.actualUnits = actualSubmissionValue;
                    // }

                    if (unitSubmissions && unitSubmissions.length) {

                        let pendingUnitsvalue = 0;

                        unitSubmissions.forEach((unitSubmission) => {
                            unitSubmission.taskUnits.forEach((taskUnit) => {
                                taskUnit.unitAssignments.forEach((unitAssignment) => {
                                    unitAssignment.unitSubmissions.forEach((unit) => {
                                        // Pending units have status as 1
                                        if (unit.status === UnitSubmissionStatus.PendingUnits) {
                                            pendingUnitsvalue += unit.submittedQuantity;
                                        }
                                    });
                                });
                            });
                        });

                        if (pendingUnitsvalue > 0) {
                            this.hasPendingUnits = true;
                            this.pendingUnits = pendingUnitsvalue;

                            const pendingUnitsErrorMessage = `There are ${this.pendingUnits} Units in Pending approval for this ${this.isProjectContext ? "project" : "engagement"}`;
                            if (this.laborErrorMessages.findIndex((message) => message.toLocaleUpperCase() === pendingUnitsErrorMessage.toLocaleUpperCase()) === -1) {
                                this.laborErrorMessages.push(pendingUnitsErrorMessage);
                            }
                        }
                    }
                })
                .catch(() => {
                    this.hasPendingUnits = undefined;
                    const errorMessage = `Error in retrieving pending units for ${this.isProjectContext ? "project" : "engagement"}`;
                    if (this.laborErrorMessages.findIndex((message) => message.toLocaleUpperCase() === errorMessage.toLocaleUpperCase()) === -1) {
                        this.laborErrorMessages.push(errorMessage);
                    }
                });
        }
    }

    /**
     * Get Pjm Aliases from Engagement Details Object
     */
    private getPjmAliases(): string[] {
        const pjmAliases: string[] = [];
        for (const projectDetails of this.projects) {
            const pjmDetails: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL1("PJM", projectDetails);
            if (pjmDetails && pjmDetails.length && pjmAliases.indexOf(pjmDetails[0].alias) === -1) {
                pjmAliases.push(pjmDetails[0].alias);
            }
        }
        return pjmAliases;
    }

    /**
     * Gets the assignments from GRM Response 
     * @param grmRequestList     
     */
    private getAssignmentsFromGRMResponse(grmRequestList: IResourceRequestResponse, projects: IProjectDetailsV2[]): IAssignmentDetails[] {
        const partnerName = "aurora";
        const assignmentDetails: IAssignmentDetails[] = [];
        for (const projectDetails of this.projects){
            if (grmRequestList.ProjectRequests && grmRequestList.ProjectRequests.length > 0) {
                for (const projectRequest of grmRequestList.ProjectRequests) {
                    if (projectRequest.ResourceRequests && projectRequest.ResourceRequests.length > 0) {
                        for (const resource of projectRequest.ResourceRequests) {
                            if (pendingApprovalStatus.indexOf(resource.ResourceRequestStatusEnum) > -1 && !projectDetails.id && resource.AssignedResource !== null) {
                                assignmentDetails.push({
                                    assignmentId: resource.ResourceRequestId.toString(),
                                    partner: partnerName,
                                    resourceAlias: resource.AssignedResource
                                });
                            } else if (projectDetails.id && projectRequest.DemandSourceId && projectRequest.DemandSourceId.substring(0, 19) === projectDetails.id && pendingApprovalStatus.indexOf(resource.ResourceRequestStatusEnum) > -1 && resource.AssignedResource !== null) {
                                assignmentDetails.push({
                                    assignmentId: resource.ResourceRequestId.toString(),
                                    partner: partnerName,
                                    resourceAlias: resource.AssignedResource
                                });
                            }
                        }
                    }
                }
            }

        }
    
        return assignmentDetails;
    }

    /**
     * Gets details of pending labor reports by calling the Time2.0 labor Service.
     * @param queryString
     */
    private async getPendingLaborDetails(assignmentIds: string[], engagementId: string, approverAlias: string): Promise<any> {
        const requestPayLoad = {
            assignmentIds,
            engagementId
        };
        return this.laborManagementService.getPendingLaborApprovalCountBasedOnAssignments(assignmentIds, engagementId, approverAlias)
            .then((data: ILaborApprovalCountBasedOnAssignments) => {
                if (data && data.count > 0) {
                    this.pendingLaborApprovalCount = this.pendingLaborApprovalCount + data.count;
                }
            }).catch((error) => {
                if (error.status !== 404) {
                    this.hasPendingLaborForEngagement = undefined;
                    const errorMessage = "Error in retrieving pending labor for an engagement/project";
                    const value = this.laborErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                    if (isUndefined(value)) {
                        this.laborErrorMessages.push(errorMessage);
                    }
                }
            });

    }


    private async checkForPendingLabor(): Promise<any>{
        const projects = this.selectedProject ? [this.selectedProject] : this.selectedEngagement.projects;
        await this.staffingService.getGRMRequestsProjectId(projects).then(async (response: IResourceRequestResponse) => {
            this.grmRequestList = response;
            const assignments: IAssignmentDetails[] = this.getAssignmentsFromGRMResponse(this.grmRequestList, projects);
            await this.laborManagementService.getBulkLaborEntriesBasedOnAssigments(assignments)
                .then((data: ILaborDetailsResponse) => {
                    if (data && data.response && data.response.length) {
                        this.hasPendingLaborForEngagement = data.response.some((laboResponse) => laboResponse.isApproverActionPending === true);
                    }
                }).catch((error) => {
                    if (error.status !== 404) {
                        this.hasPendingLaborForEngagement = undefined;
                        const errorMessage = "Error in retrieving pending labor for an engagement/project";
                        const value = this.laborErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                        if (isUndefined(value)) {
                            this.laborErrorMessages.push(errorMessage);
                        }
                    }
                });
            
            /*
            const pjmAliases: string[] = this.getPjmAliases();
            if (pjmAliases.length > 1) {
                for (const projectDetails of this.projects) {
                    if (this.sharedFunctionsService.getProjectOrServiceUserStatus(projectDetails.userStatusCode) !== ProjectUserStatusTypes.Prepay && this.sharedFunctionsService.getProjectOrServiceUserStatus(projectDetails.userStatusCode) !== ProjectUserStatusTypes.DeletedProject){
                        const pjmDetails: ITeamDetailsV2 = this.sharedFunctionsService.getPjmInfoL1("PJM", projectDetails)[0];
                        const assignmentIds: string[] = this.getAssignmentsFromGRMResponse(this.grmRequestList, projectDetails.id);
                        if (assignmentIds.length > 0) {
                            await this.getPendingLaborDetails(assignmentIds, projectDetails.engagementId, pjmDetails.alias);
                        }
                    }  
                }
            }
            else if (pjmAliases.length === 1) {
                const assignmentIds: string[] = this.getAssignmentsFromGRMResponse(this.grmRequestList);
                if (assignmentIds.length > 0) {
                    await this.getPendingLaborDetails(assignmentIds, projects[0].engagementId, pjmAliases[0]);
                }
            }
            */
            if (this.hasPendingLaborForEngagement){
                const errorMessage = "Approve/reject pending labor.";
                if (this.laborErrorMessages.findIndex((message) => message.toLocaleUpperCase() === errorMessage.toLocaleUpperCase()) === -1) {
                    this.laborErrorMessages.push(errorMessage);
                }  
            }
            else{
                this.hasPendingLaborForEngagement = false;
            }
            
        }).catch((error) => {
            if (error !== "" && error.status !== 404) {
                this.hasPendingLaborForEngagement = undefined;
                const errorMessage = "Error in retrieving Grm resource Request Details for engagement/project";
                const value = this.laborErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                if (isUndefined(value)) {
                    this.laborErrorMessages.push(errorMessage);
                }
            }
        });
    }

    /**
     * Get Unapproved expense from concur API and build expense data
     */
    private async checkForPendingExpenses(): Promise<any> {
        let queryString = "employeeId=" + this.fxpUserInfoService.getCurrentUserData().personnelNumber.toString();
        const projects = this.selectedProject ? [this.selectedProject] : this.selectedEngagement.projects;
        const expenseWbsIds: string[] = this.sharedFunctionsService.getExpenseTypeWbsIds(projects);
        for (const expenseId of expenseWbsIds) {
            queryString = queryString + "&expenseWbsId=" + expenseId;
        }
        const key = `${CacheKeys.ConcurPendingReportsApproval.KeyName}-${queryString}`;
        this.cacheService.get(key, () => this.concurService.getPendingReportsforApproval(queryString), CacheKeys.ConcurPendingReportsApproval.Duration)
            .then((response: IConcurPendingReportsResponse[]) => {
                if (response && response.length > 0 && response.entries && response.entries.length > 0) {
                    this.hasPendingExpensesForEngagement = true;
                    const errorMessage = "Approve/reject pending expenses.";
                    if (this.laborErrorMessages.findIndex((message) => message.toLocaleUpperCase() === errorMessage.toLocaleUpperCase()) === -1) {
                        this.laborErrorMessages.push(errorMessage);
                    }
                }
                else{
                    this.hasPendingExpensesForEngagement = false;
                }
            }).catch((error) => {
                if (error && (error.status && error.status === 404)  || (error.statusCode && error.statusCode === 404))
                {
                    this.hasPendingExpensesForEngagement = false;                    
                }
                else
                {
                    this.hasPendingExpensesForEngagement = undefined;
                    const errorMessage = "Error in retrieving pending expenses for engagement/project";
                    const value = this.laborErrorMessages.find((message) => message.toLocaleLowerCase() === errorMessage);
                    if (isUndefined(value)) {
                        this.laborErrorMessages.push(errorMessage);
                    }
                }
            });
    }

    private transform(value: number, currencyCode?: string): string {
        if (!value) {
            return "0.00";
        }

        if (!currencyCode) {
            return this.numberPipe.transform(value, "1.2-2");
        }

        const result: string = this.currencyPipe.transform(value, currencyCode, "symbol-narrow");
        if (result[0] === "-") {
            let newResult = "";
            let flag = true;
            for (let i = 1; i < result.length; i++) {
                if (flag && !isNaN(Number(result[i]))) {
                    newResult += "-" + result[i];
                    flag = false;
                } else {
                    newResult += result[i];
                }
            }
            return newResult;
        }

        return result;
    }
}

