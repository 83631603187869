import { Injectable, Inject } from "@angular/core";
import { APIConstants, CacheKeys, Services, SourceConstants } from "../application.constants";
import { CacheService } from "./cache.service";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";
import { Store } from "@ngrx/store";
import { IState } from "../../store/reducers";
import { IAddRoleSapInputObject } from "./contracts/add-roles.contracts";
import { IChangeRequest, CrEngagmentType, ICrDemandOutput, ICostRatePeriod, IBlendedCostRequest, ICostRateSchedule, IExistingDemand, ICrUnitOutput, ResourceTypeCode } from "./contracts/changerequest.contract";
import {
    ICopyFinancialPlan, IEngagementDetails, IEngagementSearchInputObject, IPlanForecastDataParameters,
    ISearchResultsObject, ICostRateInput, ICostRateApiOutput, IWBSListV2, IClinSlinApiResponse, IExportToExcelResult, ReportType
} from "./contracts/project.service.contracts";
import { ICreateInternalEngagementOutput, ICostCenterValidationOutput } from "../../components/new-internal-engagement/new-internal-engagement.contracts";
import { IDelegationDetailsV2 } from "./contracts/delegation.v2.service.contracts";
import { IEngagement } from "./contracts/financial.service.contracts";
import { IEngagementFinancial, IEntityFinancials, IForecast } from "../../components/financial-mgmt/financial.model";
import { IFinancialSummaryDetails, IBillRate, ICostRateInfo } from "./contracts/changerequest.contract";
import { IWbsDetailsV2, IEngagementDetailsApiV2, IProjectDetailsV2, IEngagementDetailsV2, ITaskDetailsV2 } from "./contracts/wbs-details-v2.contracts";
import { IUnitsViewModel, IUnitEditRequestDetails, IUnitTransactionResponse } from "./contracts/actuals.contracts";
import { LoadClinslinFail, LoadClinslinSuccess, LoadManualClinslin } from "../../store/clinslin/clinslin.action";
import { IInternalEngagementFinancialsListV2, IEngagementFinancialsListV2 } from "./contracts/portfolio.model";
import { IFinancialRoles } from "./contracts/projectservice-functions.contract";
import { IFinancialPlanResponse } from "./contracts/financial-plan.contracts";
import moment from "moment";
import momentTz from "moment-timezone";
import { INpcActualsResponse, INpcProjectActuals, INpcActualsUpdateObject } from "./contracts/npc.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { ErrorSeverityLevel } from "@fxp/fxpservices";
import { IEcifIoConsumptionAPI } from "./contracts/ecif-io-consumed-modal.contracts";
import { IExpenseDetailsApi } from "./contracts/expense.actual.contract";
import { IProjectClosureRequest, IWbsETMDetails } from "./contracts/project.closure.contract";
import { IRatableProjectDetailsApi } from "./contracts/projectclosure.ratable.contract";

@Injectable()
export class ProjectService extends DmServiceAbstract {

    public engagementDetails: IEngagementDetails;
    private projectServiceBaseUri: string;
    private projectServiceBaseUriv2: string;
    private projectServiceBaseUriV21: string;
    private subscriptionKey: string;
    private deSubscriptionKey: string;
    private delegationServiceBaseUriV2By: string;
    private delegationServiceBaseUriV2To: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(dmLogger, Services.ProjectService);
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Initialize the project service with various URIs and Subscription Keys
     */
    public initializeConfig(): void {
        this.projectServiceBaseUri = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v1.0";
        this.projectServiceBaseUriv2 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.projectServiceBaseUriV21 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.1";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        this.deSubscriptionKey = this.configManagerService.getValue<string>("delegationServiceSubscriptionKey");
        this.delegationServiceBaseUriV2By = this.configManagerService.getValue<string>("delegationServiceBaseUriV2") + "delegatedBy/";
        this.delegationServiceBaseUriV2To = this.configManagerService.getValue<string>("delegationServiceBaseUriV2") + "delegatedTo/";
    }

    /**
     * Get wbs details from v2 api which has engagements, projects, tasks, services, teams
     *
     * @param {string} engagementOrProjectId
     * @param {boolean} includeEngagements
     * @param {boolean} [includeProjects]
     * @param {boolean} [includeServices]
     * @param {boolean} [includeTasks]
     * @param {boolean} [includeTeams]
     * @returns {Promise<IWbsDetailsV2>}
     * @memberof ProjectService
     */
    public getWbsDetailsV2(engagementOrProjectId: string, includeEngagements: boolean, includeProjects: boolean, includeServices: boolean, includeTasks: boolean, includeTeams: boolean): Promise<IWbsDetailsV2> {
        this.initializeConfig();
        const url = `${this.projectServiceBaseUriv2}/wbs/${engagementOrProjectId}/details?includeEngagement=${includeEngagements}&includeProjects=${includeProjects}&includeServices=${includeServices}&includeTasks=${includeTasks}&includeTeams=${includeTeams}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.ProjectDetailsAPI);
    }

    /**
     * Delegation V2. Get a list of delegated Engagements and projects that are delegated by user
     * @param userAlias
     */
    public getMyDelegatedBy(userAlias: string): Promise<IDelegationDetailsV2[]> {
        const url = this.delegationServiceBaseUriV2By + userAlias;
        return this.dataService.getData(url, this.deSubscriptionKey, APIConstants.DelegatedByDetailsAPI);
    }

    /**
     * Delegation V2. Get a list of delegated Engagements and projects that are delegated to user
     * @param userAlias
     */
    public getMyDelegatedTo(userAlias: string): Promise<IDelegationDetailsV2[]> {
        const url = this.delegationServiceBaseUriV2To + userAlias;
        return this.dataService.getData(url, this.deSubscriptionKey, APIConstants.DelegatedToDetailsAPI);
    }

    /**
     * Gets engagement details for the given engagement or Project ID from the Project Management API; can also include projects, services, L3 info, and demands in the call
     * Engagement ID should be passed in the engagement context, project ID should be passed in the project context.
     * Todo: we have an existing API endpoint for getting the engagement by project ID, but the current application is not configured
     * to use it. In the future, we should implement a call to that endpoint instead of passing project ID here.
     * @param engagementOrProjectId
     * @param includeProjects
     * @param includeServices
     * @param includeL3
     * @param includeDemands
     */
    // todo remove v1
    public getEngagementDetailsByEngagementId(engagementOrProjectId: string, includeProjects: boolean = false, includeServices: boolean = false, includeL3: boolean = false, includeDemands: boolean = false): Promise<IEngagementDetails> {
        this.initializeConfig();
        let url: string;

        if (includeDemands) {
            url = `${this.projectServiceBaseUri}/Engagements/${engagementOrProjectId}?isProjects=${includeProjects}&isServices=${includeServices}&isWBSL3=${includeL3}&demands=Labor`;
        } else {
            url = `${this.projectServiceBaseUri}/Engagements/${engagementOrProjectId}?isProjects=${includeProjects}&isServices=${includeServices}&isWBSL3=${includeL3}`;
        }

        return this.dataService.getData(url, this.subscriptionKey, APIConstants.EngagementDetailsByEngagementId);
    }

    /**
     * Gets a list of units(actuals) data via its wbs id.
     *
     * @param {string} wbsId
     * @returns {Promise<IUnitsViewModel[]>}
     * @memberof ProjectService
     */
    public getActualsData(wbsId: string): Promise<IUnitsViewModel[]> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/units`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetActuals);
    }

    /**
     * Gets a list of units(actuals) transactions data via its wbs id.
     *
     * @param {string} wbsId
     * @returns {Promise<IUnitsViewModel[]>}
     * @memberof ProjectService
     */
    public getActualsTransactionDetails(wbsId: string, demandId?: string): Promise<IUnitTransactionResponse> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/units/actuals?demandId=${demandId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetActuals);
    }

    /**
     * Updates units(actuals) demand based on task Id.
     *
     * @param {IUnitEditRequestDetails} updatedUnitDetails
     * @param {string} taskId
     * @returns {Promise<any>}
     * @memberof ProjectService
     */
    public updateActuals(updatedUnitDetails: IUnitEditRequestDetails, taskId: string): Promise<any> {
        return this.dataService.patchData(`${this.projectServiceBaseUriv2}/wbs/${taskId}/units`,
            this.subscriptionKey, APIConstants.UpdateActuals, updatedUnitDetails, undefined, false);
    }

    /**
     * Gets Internal Engagements Financials List
     *
     * @param {IEngagementList[]} engagementList
     * @returns {Promise<IInternalEngagementFinancialsListV2[]>}
     * @memberof ProjectService
     */
    public getInternalEngagementFinancialsListV2(engagementList: string[]): Promise<IInternalEngagementFinancialsListV2[]> {
        const payload = [];
        let engIds = "";
        if (engagementList && engagementList.length) {
            for (const engagement of engagementList) {
                payload.push({ id: engagement });
                engIds = engIds + engagement + "-";
            }
        }
        const url = `${this.projectServiceBaseUriv2}/internal/financials/bulk`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetInternalEngagementFinancialsList, payload);
    }

    /**
     * Gets financial plans by wbs id
     *
     * @param {string} wbsId
     * @returns {Promise<IFinancialPlanResponse>}
     * @memberof ProjectService
     */
    public getFinancialPlansByWbsId(wbsId: string): Promise<IFinancialPlanResponse> {
        const url = `${this.projectServiceBaseUriv2}/financials/${wbsId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetContactsByWbsId);
    }

    /**
     * Get Financials for a specific Engagement via its Engagement ID from the Project Management API,
     * Can also pass a project ID and will get the engagement and project's financial info for the given project.
     *
     * This takes in both a single WBS ID, or you can pass an array of ID objects. But the ID Object params are legacy and will be deleted soon.
     * @param engagementOrProjectId
     * @param financialPlans
     * @param services
     */
    public getFinancialsByEntityId(engagementOrProjectId: string | IEngagement[], financialPlans: boolean, services: boolean): Promise<IEngagementFinancial> {
        let engagements: IEngagement[];
        if (Array.isArray(engagementOrProjectId)) {
            /* This is legacy for an old duplicate function that took in the array of IDs instead of a single ID.
            The only instance of this is in associated internal engagement component, and will be deprecated soon. */
            engagements = engagementOrProjectId;
        } else {
            engagements = [{ id: engagementOrProjectId }]; /* Can be an engagement or project ID */
        }
        const request = {
            engagements,
            financialPlans,
            services
        };
        const url = `${this.projectServiceBaseUri}/Engagements/Financials`;
        const key = CacheKeys.WbsFinancials.KeyName + engagementOrProjectId;
        return this.cacheService.get(key,
            () => this.dataService.postData(url, this.subscriptionKey, APIConstants.EngagementFinancials, request),
            CacheKeys.WbsFinancials.Duration);
    }

    /**
     * Get Financials for a specific Engagement/Project/Service through wbsId from the PMS
     * @param engagementOrProjectId
     * @param financialPlans
     * @param services
     */
    public getFinancialsByEntityIdV2(wbsId: string, includeFinancialPlans: boolean, includeFinancialSummary: boolean, includeAdditionalSummaryData: boolean): Promise<IEntityFinancials> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/financials?financialPlans=${includeFinancialPlans}&financialSummary=${includeFinancialSummary}&includeAdditionalSummaryData=${includeAdditionalSummaryData}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.EngagementFinancials);
    }


    /**
     * Get Financials for a specific Engagement via its Engagement ID from the Project Management API,
     * This takes 5 engagement ids at max
     *
     *
     * @param engagementOrProjectId
     * @param financialPlans
     * @param services
     */
    public getCustomerEngagementFinancialsListV2(arrayOfEngagementIds: IEngagement[]): Promise<IEngagementFinancialsListV2[]> {
        const request = arrayOfEngagementIds;
        const url = `${this.projectServiceBaseUriv2}/wbs/customerdelivery/financials/bulk`;
        let engagementIds: string = "";
        arrayOfEngagementIds.forEach((engagement: IEngagement) => {
            engagementIds = engagementIds + "-" + engagement.id;
        });
        const key = CacheKeys.CustomerEngagementFinancials.KeyName + JSON.stringify(engagementIds);
        return this.cacheService.get(key,
            () => this.dataService.postData(url, this.subscriptionKey, APIConstants.EngagementFinancials, request),
            CacheKeys.CustomerEngagementFinancials.Duration);
    }

    /**
     * Gets a list of Engagements and its projects for the logged in user from the Project Management API.
     * This is the V2 function from PMS
     */
    public getEngagementListForLoggedInUserV2(loadFromCache: boolean): Promise<IWBSListV2> {
        const url = `${this.projectServiceBaseUriv2}/wbs?includeEngagements=true&includeProjects=true&fromCache=${loadFromCache}`;
        const payload = {
            wbsIds: [], /* We want all the entities for the user, so we do not pass any wbsIds. Empty array is currently required or else API will throw 500 */
            pageNumber: 0, /* Start at the beginning */
            recordCount: 0, /* Start at the beginning */
        };

        return this.dataService.postData(url, this.subscriptionKey, APIConstants.EngagementListForLoggedInUser, payload)
            .then((response: IWBSListV2) => {
                return response as any; /* Returning the straight response here in order to quiet TS errors. */
            })
            .catch((error: any) => {
                if (error.status === 404) { /* Catch 404 errors and format them as empty arrays, they are valid responses */
                    return Promise.resolve({
                        engagements: [],
                        projects: []
                    });
                }
                let errorMessage: string;
                if (error.data) {
                    error = error.data;
                    if (error.innerErrors && error.innerErrors.length && error.innerErrors[0].messages && error.innerErrors[0].messages.length) {
                        errorMessage = error.innerErrors[0].messages[0];
                    }
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                if (!errorMessage) {
                    errorMessage = "Unable to retrieve Engagement List.";
                }
                const correlationId: string = DataService.getCorrelationIdFromError(error);
                const correlationIdMessage: string = " Correlation ID: " + correlationId;
                this.logError(SourceConstants.Method.GetEngagementListForLoggedInUserV2, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.reject(errorMessage + correlationIdMessage);
            });
    }

    /**
     * Copies a financial plan for an engagement by taking a snapshot, using the Project Management API
     * @param engagementId
     * @param versionId
     * @param snapshotName
     */
    public copyFinancialPlan(engagementId: string, snapshotName: string): Promise<ICopyFinancialPlan> {
        const url = `${this.projectServiceBaseUriv2}/engagement/${engagementId}/financialplan/snapshot?snapshotName=${snapshotName}`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.CopyFinancials, null);
    }

    /**
     * Updates the Snapshot name of a copied financial plan for an engagement, using the Project Management API
     * @param engagementId
     * @param versionId
     * @param snapshotName
     */
    public updateFinancialSnapshotName(engagementId: string, versionId: string, snapshotName?: string): Promise<any> {
        const request = {
            Description: snapshotName
        };
        const url = `${this.projectServiceBaseUri}/Engagements/${engagementId}/Financials/${versionId}`;
        return this.dataService.patchData(url, this.subscriptionKey, APIConstants.CopyFinancials, request);
    }

    /**
     * Search engagements with specific input using the Project Management API
     * @param input
     */
    public searchEngagements(input: IEngagementSearchInputObject): Promise<ISearchResultsObject> {
        const url = `${this.projectServiceBaseUri}/Engagements/Search`;
        return this.dataService.postData<ISearchResultsObject>(url, this.subscriptionKey, APIConstants.SearchEngagements, input);
    }

    /**
     * Validate the given cost center using the Project Management API
     * @param costCenterId
     */
    public validateCostCenter(costCenterId: string, chargeTypeValue: string = "CostCenter"): Promise<ICostCenterValidationOutput> {
        const url = `${this.projectServiceBaseUri}/internal/validatecostcenter/${costCenterId}/${chargeTypeValue}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.ValidateCostCenter);
    }

    /**
     * Upload a file to the blob using the Project Management API
     * @param input
     * @param engagementTypeCode
     */
    public uploadFileToBlob(input: FormData, engagementTypeCode: string): Promise<any> {
        // 97 code for one of engagement type called  "Unit based" for which we file upload is not required.
        if (engagementTypeCode === "97" || !input) {
            return Promise.resolve("");
        } else {
            const url = `${this.projectServiceBaseUri}/internal/engagement/attachment`;
            return this.dataService.postData(url, this.subscriptionKey, APIConstants.UploadFiletoBlob, input, true);
        }
    }

    /**
     * Validates Thresholds of a given Engagement Id before activation
     * @param engagementId
     */
    public validateRelease(engagementId: string): Promise<any> {
        const url = `${this.projectServiceBaseUriv2}/engagement/${engagementId}/releaseandactivate/validate`;
        // Suppress 400 errors from displaying in the banner for this api
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.ValidateRelease, [400], false, false, false, null, true);
    }

    /**
     * Gets the Clin Slin for the given engagement ID by calling the Project Service API
     * @param engagementId
     */
    public getClinSlinForEngagementId(engagementId: string, ngrxLoad: boolean = false): Promise<any> {
        const url = `${this.projectServiceBaseUri}/Engagement/ClinSlin/${engagementId}`;
        if (!ngrxLoad) {
            this.store.dispatch(new LoadManualClinslin(engagementId));
        }
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetClinSlin)
            .then((response: IClinSlinApiResponse) => {
                if (!ngrxLoad) {
                    this.store.dispatch(new LoadClinslinSuccess(engagementId, response));
                }
                return response;
            }).catch((err) => {
                if (!ngrxLoad) {
                    this.store.dispatch(new LoadClinslinFail(engagementId, err));
                }
                let errorMessage: string;
                if (err.data) {
                    const error = err.data;
                    if (error.innerErrors && error.innerErrors.length && error.innerErrors[0].messages && error.innerErrors[0].messages.length) {
                        errorMessage = error.innerErrors[0].messages[0];
                    }
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                if (!errorMessage) {
                    errorMessage = "Unable to retrieve Engagement List.";
                }
                this.logError(SourceConstants.Method.GetClinSlinForEngagementId, err, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.reject(err);
            });
    }

    /**
     * Gets the Clin Slin for the given project ID by calling the Project Service API
     * @param projectId
     */
    public getClinSlinForProjectId(projectId: string, ngrxLoad: boolean = false): Promise<any> {
        const url = `${this.projectServiceBaseUri}/Project/ClinSlin/${projectId}`;
        if (!ngrxLoad) {
            this.store.dispatch(new LoadManualClinslin(projectId));
        }
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetClinSlin)
            .then((response: IClinSlinApiResponse) => {
                if (!ngrxLoad) {
                    this.store.dispatch(new LoadClinslinSuccess(projectId, response));
                }
                return response;
            }).catch((err) => {
                if (!ngrxLoad) {
                    this.store.dispatch(new LoadClinslinFail(projectId, err));
                }
                let errorMessage: string;
                if (err.data) {
                    const error = err.data;
                    if (error.innerErrors && error.innerErrors.length && error.innerErrors[0].messages && error.innerErrors[0].messages.length) {
                        errorMessage = error.innerErrors[0].messages[0];
                    }
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                if (!errorMessage) {
                    errorMessage = "Unable to retrieve ClinSlin For Project.";
                }
                this.logError(SourceConstants.Method.GetClinSlinForProjectId, err, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.reject(err);
            });
    }

    /**
     * Gets the planned forecast parameters for the given engagement based on the Engagement ID by calling the Project Service API.
     * @param engagementId
     * @param loadFromCache
     */
    public getPlanForecastParamsForEngagementOrProject(engagementId: string): Promise<IPlanForecastDataParameters> {

        /* Suppress 403 Error codes as they're thrown when user doesn't have access */
        const suppressErrorCode = 403;
        const url = `${this.projectServiceBaseUri}/Engagement/PlanForecast/${engagementId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetPlanForecastParams, [suppressErrorCode]);
    }

    /**
     * Add a role to an Internal Engagement
     * @param input
     */
    public addRoleToInternalEngagement(input: IAddRoleSapInputObject): Promise<ICreateInternalEngagementOutput> {
        return this.dataService.postData(`${this.projectServiceBaseUri}/internal/engagement/role`,
            this.subscriptionKey,
            APIConstants.AddRoleToInternalEngagement, input);
    }

    /**
     * Getting Cost Rate by resourceBPID
     *
     * @param {string} resourceBpid
     * @param {string} projectId
     * @returns {Promise<any>}
     * @memberof ProjectService
     */
    public getCostRate(costRateInput: ICostRateInput[]): Promise<ICostRateApiOutput> {
        const url = `${this.projectServiceBaseUri}/costRate`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetCostRateByResourceBPID, costRateInput);
    }

    /**
     * Get Non Contractual CRs assigned to the caller, where he/she is an approver
     * Note: May be removed along with My Financial Approvers page
     *
     * @param input
     */
    public getNonContractualAmendments(): Promise<IChangeRequest[]> {
        return this.dataService.getData(`${this.projectServiceBaseUri}/changerequests`,
            this.subscriptionKey,
            APIConstants.GetCRList);
    }

    /**
     * get existing roles for an engagement
     */
    public getRoleDetailsByWbsId(wbsId: string): Promise<{
        changeRequestDetails: IExistingDemand[];
        billRates: IBillRate[];
    }> {
        return this.dataService.getData(`${this.projectServiceBaseUriv2}/financials/changerequest/wbsid/${wbsId}/roles?includeBillRate=true`,
            this.subscriptionKey,
            APIConstants.GetRoleDetailsByWbsId
        );
    }

    // todo docs
    public getCRSummaryScreenPlanDetails(wbsId: string): Promise<IFinancialSummaryDetails[]> {
        return this.dataService.getData(`${this.projectServiceBaseUri}/financials/${wbsId}`,
            this.subscriptionKey,
            APIConstants.GetCRSummaryScreenPlanDetails
        );
    }

    /**
     * get cost rate for a given resource
     */
    public getCostRateByTaskId(taskId: string, activityType: string, resourceType: string, resourceLocation: string, unit?: string): Promise<ICostRateInfo> {
        return this.dataService.getData(`${this.projectServiceBaseUri}/costrate/task/${taskId}?activityType=${activityType}&resourceType=${resourceType}&resourceLocation=${resourceLocation}&unit=${unit}`,
            this.subscriptionKey,
            APIConstants.GetCostRateByTaskId
        );
    }

    /**
     * Upload Excel Data Object to Project Service. An email will be sent to user once excel is generated
     */
    public postExportToExcel(wbsId: string, reportType: ReportType, data: IExportToExcelResult): Promise<any> {
        return this.dataService.postData(`${this.projectServiceBaseUriv2}/wbs/${wbsId}/report/${reportType}`,
            this.subscriptionKey,
            APIConstants.UploadExcelData,
            data
        );
    }

    /**
     * Fill Roles from Current Roles on the Engagement for PubSec Engagements
     * @param existingRoles
     */
    public getFinancialRolesFromExistingRole(existingRoles: IExistingDemand[]): IFinancialRoles[] {
        const roleValues = [];
        for (const role of existingRoles) {
            if (roleValues.filter((r) => r.rolePartNumber === role.rolePartNumber).length === 0) {
                roleValues.push({
                    roleName: role.roleDescription,
                    rolePartNumber: role.rolePartNumber,
                    activityCode: role.roleActivityCode,
                    isFte: true,
                    isCleared: true
                });
            }
        }
        return roleValues;
    }

    /**
     * Extract the engagement type from user status code for the Change Request.
     *
     * @private
     * @param {string} userStatusCode
     * @returns {CrEngagmentType}
     * @memberof LaborRequestModalComponent
     */
    public getEngagementTypeFromUserStatusCode(engagementDetails: IEngagementDetailsApiV2): CrEngagmentType {
        const codeIndex: number = engagementDetails.userStatusCode.indexOf("DT") + 2;
        const codeValue: number = Number(engagementDetails.userStatusCode.charAt(codeIndex));

        switch (codeValue) {
            case 1: return CrEngagmentType.DT1;
            case 2: return CrEngagmentType.DT2;
            case 3: return CrEngagmentType.DT3;
            case 4: return CrEngagmentType.DT4;
            case 5: return CrEngagmentType.DT5;
            default: return undefined;
        }
    }

    /**
     * Get the cost rate data once the role and assigned task have been chosen for the change request demand.
     */
    public retrieveLaborCostRate(editedDemand: ICrDemandOutput): Promise<ICostRateInfo> {
        let resourceLocation: string;
        let activityCode: string;
        let resourceType: string;

        if (editedDemand.currentState.role) {
            activityCode = editedDemand.currentState.role.activityCode;
            resourceLocation = editedDemand.currentState.resourceLocationKey;
            resourceType = editedDemand.currentState.role.isFte ? "0ACT" : "ZEXS";
        } else if (editedDemand.currentState.existingDemand && !editedDemand.currentState.role) {
            activityCode = editedDemand.currentState.existingDemand.roleActivityCode;
            resourceLocation = editedDemand.currentState.resourceLocationKey ? editedDemand.currentState.resourceLocationKey : editedDemand.currentState.existingDemand.resourceLocationKey.slice(-4);
            resourceType = editedDemand.currentState.existingDemand.resourceType;
        }

        editedDemand.currentState.isCostRateResolved = false;
        const laborUom = "H";
        return this.getCostRateByTaskId(editedDemand.currentState.assignedTask.projectId, activityCode, resourceType, resourceLocation, laborUom);
    }

    /**
    * Get the cost rate data once the role and assigned task have been chosen for the change request demand.
    */
    public retrieveUnitCostRate(editedDemand: ICrUnitOutput): Promise<ICostRateInfo> {
        let activityCode: string;
        let resourceType: string;
        let resourceLocation: string;
        // Initializing these values with default values.
        let uOM: string = "EA";
        if (editedDemand.currentState.role) {
            activityCode = editedDemand.currentState.role.activityCode;
            resourceType = ResourceTypeCode.Unit;
        } else if (editedDemand.currentState.existingResource && !editedDemand.currentState.role) {
            activityCode = editedDemand.currentState.existingResource.roleInfo.activityCode;
            resourceType = editedDemand.currentState.existingResource.resourceType;
        }
        /// Logic for retrieving resource location
        /// if this modification is for existing resource pick the resource location from existing resource.
        /// Else if this is for new for adding new resource pull the location from task
        if (editedDemand.currentState.existingResource && editedDemand.currentState.existingResource.resourceLocation) {
            resourceLocation = editedDemand.currentState.existingResource.resourceLocation;
            uOM = editedDemand.currentState.existingResource.uoM;
        } else if (editedDemand.currentState.assignedTask && editedDemand.currentState.assignedTask.resourceLocation) {
            resourceLocation = editedDemand.currentState.assignedTask.resourceLocation;
        }
        editedDemand.currentState.isCostRateResolved = false;
        return this.getCostRateByTaskId(editedDemand.currentState.assignedTask.projectId, activityCode, resourceType, resourceLocation, uOM);
    }

    /**
     * Gets the cost rate for the given hours
     */
    public getBlendedCostRate(blendedCostRequest: IBlendedCostRequest): number {
        const weeklySpread = this.getWeeklySpread(blendedCostRequest.startDate, blendedCostRequest.endDate, blendedCostRequest.additionalHours, blendedCostRequest.costPeriodList, blendedCostRequest.existingHours, blendedCostRequest.isCurrentDateStartDate);
        const blendedCostRate = this.getBlendedRate(weeklySpread, blendedCostRequest.existingHours, blendedCostRequest.additionalHours, blendedCostRequest.staffedCost, blendedCostRequest.staffedHours);
        return blendedCostRate;
    }

    /**
     * Derives project ID from a given task ID.
     *
     * @param {string} taskId
     * @returns {string}
     * @memberof ProjectService
     */
    public getProjectIdFromTaskId(taskId: string): string {
        if (taskId) {
            const projectId = taskId.split(".").slice(0, 3).join("."); // removing task portion of id
            return projectId.substring(0, projectId.length - 3) + "000";
        }
        return "000";

    }

    /**
     * 
     * @param wbsId wbsId can be any wbsId > l0 and < l3
     * @param engagementDetails engagementDetails object
     * @returns projectObject
     */
    public getProjectFromWbsId(wbsId: string, engagementDetails: IEngagementDetailsV2): IProjectDetailsV2 {
        if (!engagementDetails || !engagementDetails.projects || !engagementDetails.projects.length || wbsId.length < 19) {
            return undefined;
        }
        if (wbsId.length === 19 && wbsId.endsWith("000")) {
            return engagementDetails.projects.find((p) => p.id === wbsId);
        }
        // Find project by iterating through all project.services
        for (const proj of engagementDetails.projects) {
            if (proj.services.some((s) => s.id === wbsId)) {
                return proj;
            }
        }
    }

    /**
     * Gets the list of task IDs associated with a project
     *
     * @param {IProjectDetailsV2} project
     * @returns {*} List of task IDs for the given project, no duplicates
     * @memberof ProjectService
     */
    public getTaskIdListFromProject(project: IProjectDetailsV2): any {
        if (!project || !project.services || !project.services.length) {
            return [];
        }

        const taskIds: string[] = [];
        for (const service of project.services) {
            if (service && service.tasks && service.tasks.length) {
                for (const task of service.tasks) {
                    if (taskIds.indexOf(task.id) < 0) {
                        taskIds.push(task.id);
                    }
                }
            }
        }
        return taskIds;
    }

    /**
     * Determines the forecasts for an engagement.
     * 
     * @param {wbsId}
     */
    public getForecast(wbsId: string, version?: string): Promise<IForecast> {
        let url = `${this.projectServiceBaseUri}/forecast/engagement/${wbsId}`;
        if (version) {
            url = `${this.projectServiceBaseUri}/forecast/engagement/${wbsId}?planVersion=${version}`;
        }
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetForecast);
    }

    /**
     * Updates forecast hours based on schedule Id.
     */
    public updateForecast(changedForecastDetails: Array<{
        scheduleId: string;
        hours: number;
    }>, wbsId: string): Promise<any> {
        const inputData = changedForecastDetails;
        const url = `${this.projectServiceBaseUri}/wbsId/${wbsId}/forecast`;
        return this.dataService.patchData(url,
            this.subscriptionKey, APIConstants.UpdateForecast, inputData, undefined, false);
    }

    /**
     * Updates the NPC actuals by sending the object to the PMS APi
     *
     * @param {INpcActualsUpdateObject} updateObject the object built with the updated data to send back to the API
     * @param {string} wbsId the wbs ID for the engagement or project. This is passed in to avoid an undefined url, but this API doesn't actually look at this.
     * @returns {Promise<any>}
     * @memberof ProjectService
     */
    public updateNpcActuals(updateObject: INpcActualsUpdateObject, wbsId: string): Promise<any> {
        return this.dataService.postData(`${this.projectServiceBaseUriv2}/wbs/${wbsId}/npc`,
            this.subscriptionKey, APIConstants.UpdateNpcActuals, updateObject);
    }

    /**
     * Gets the ECIF IO Consumption
     * @param wbsId wbsId
     * @returns {Promise<IEcifIoConsumptionAPI[]}
     */
    public getEcifIoConsumption(wbsId: string): Promise<IEcifIoConsumptionAPI[]> {
        const url = `${this.projectServiceBaseUriv2}/wbs/ecifFunding/detail/${wbsId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetEcifIoConsumptionDetails);
    }

    /**
     * Gets the Expense Actual Details
     * @param wbsId wbsId
     * @returns {Promise<IExpenseDetailsApi}
     */
    public getExpenseDetails(wbsId: string): Promise<IExpenseDetailsApi[]> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/expensedetails`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetActualExpenseDetails);
    }

    /**
     * Gets the NPC Actuals for Non-Procured Materials from the PMS/Project Service API from SAP.
     */
    public getNpcActuals(wbsId: string): Promise<INpcProjectActuals[]> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/npc`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetNpcActuals).then((response: INpcActualsResponse) => {
            /* Grab and return the array of npc actuals from the response. If none are available for the engagement, a 404 will be thrown instead of an empty array */
            return Promise.resolve(response.projects);
        });
    }

    /**
     * Gets the task with the given task Id from the given engagement details object.
     *
     * @param {IEngagementDetailsV2} engagementDetails
     * @param {string} taskId
     * @returns {ITaskDetailsV2} The task with the matching task Id, or undefined if not found/if data is missing
     * @memberof ProjectService
     */
    public getTaskByTaskIdFromEngagement(engagementDetails: IEngagementDetailsV2, taskId: string): ITaskDetailsV2 {
        if (engagementDetails && taskId && engagementDetails.projects) {
            for (const project of engagementDetails.projects) {
                if (project && project.services) {
                    for (const service of project.services) {
                        if (service && service.tasks) {
                            for (const task of service.tasks) {
                                if (task && task.id && task.id === taskId) {
                                    return task;
                                }
                            }
                        }
                    }
                }
            }
        }
        return undefined;
    }

    /**
     * Trigger Project Clsoure Process by calling the orchestrate function.
     *
     * @param {FormData} input
     * @returns {Promise<string>}
     * @memberof ProjectService
     */
    public orchestrateStatusUpdate(input: FormData): Promise<void> {
        const url = `${this.projectServiceBaseUriv2}/wbs/updateProjectClosureStatus`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.UpdateProjectClosureStatus, input, true, undefined, [400]);
    }

    /**
     * Calls GetETMDetailsAsync to fetch etm details.
     *
     * @param {string} input
     * @returns {Promise<string>}
     * @memberof ProjectService
     */
    public getETMDetails(wbsID: string): Promise<IWbsETMDetails> {
        const url = `${this.projectServiceBaseUriv2}/wbs/wbsRecoETMDetails?wbsId=${wbsID}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetPJCETMDetails);
    }

    /**
     * Calls GetETMDetailsAsync to fetch etm details.
     *
     * @param {string} orchestrationId
     * @returns {Promise<string>}
     * @memberof ProjectService
     */
    public getPJCMasterData(orchestrationId: string): Promise<IProjectClosureRequest> {
        const url = `${this.projectServiceBaseUriv2}/wbs/wbsPJCMasterDataDetails?orchestartionID=${orchestrationId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetPJCMasterData);
    }

    /**
     * Calls GetRatableProjectDetails to fetch Ratable project revenue details.
     *
     * @param {string} wbsId
     * @returns {Promise<IRatableProjectDetailsApi>}
     * @memberof ProjectService
     */
    public getRatableProjectDetails(wbsId: string): Promise<IRatableProjectDetailsApi> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/ratableWbsDetails`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetRatableProjectDetails);
    }

    /**
     * Get the weekly spread for given start and end date with the cost rate based given cost period
     * @param startDate
     * @param endDate
     * @param duration
     * @param costPeriodList
     */
    private getWeeklySpread(startDate: Date, endDate: Date, duration: number, costPeriodList: ICostRatePeriod[], existingDuration: number, isCurrentDateStartDate: boolean): ICostRateSchedule[] {
        const costRateSchedule: ICostRateSchedule[] = [];
        const saturday: number = 6;
        const numberofWeekDays: number = 7;
        const pstTimezone: string = "America/Los_Angeles";

        // Need to use PST to be inline with SAP
        // If current date is start date convert date to PST timezone, else just set timezone.
        let planStartDate = isCurrentDateStartDate ? momentTz(startDate).tz(pstTimezone) : momentTz(startDate).tz(pstTimezone, true);
        let planEndDate = momentTz(endDate).tz(pstTimezone, true);

        // Set the start date of the weekday as Saturday and end date as Friday to be inline with SAP
        if (planStartDate.day() !== saturday) {
            planStartDate = momentTz(startDate).tz(pstTimezone, true).startOf("week").isoWeekday(saturday);
        }
        if (planEndDate.day() !== saturday) {
            planEndDate = momentTz(planEndDate).tz(pstTimezone, true).startOf("week").isoWeekday(saturday);
        }

        while (planStartDate <= planEndDate) {
            const scheduleStartDate = momentTz(planStartDate).tz(pstTimezone, true);
            const scheduleEndDate = momentTz(planStartDate).tz(pstTimezone, true).add(saturday, "days");

            costRateSchedule.push(
                {
                    startDate: scheduleStartDate,
                    endDate: scheduleEndDate,
                    costRate: 0,
                    duration: 0
                });
            planStartDate = momentTz(planStartDate).tz(pstTimezone, true).add(numberofWeekDays, "days");
        }

        // Equally spread the duration and update the cost rate to the weekly spread based on the given cost period.
        const noOfWeeks = costRateSchedule.length;
        const totalDuration = duration + existingDuration;
        if (noOfWeeks > 0) {
            const weeklyHours = Math.floor(totalDuration / noOfWeeks);
            let totalSpreadDuration = 0;
            for (let i = 0; i < noOfWeeks; i++) {
                totalSpreadDuration = totalSpreadDuration + weeklyHours;
                for (const costPeriod of costPeriodList) {
                    const periodStartDate = moment(costPeriod.startDate).tz(pstTimezone, true);
                    const periodEndDate = moment(costPeriod.endDate).tz(pstTimezone, true);

                    if (costRateSchedule[i].startDate.isBetween(periodStartDate, periodEndDate, "day", "[]") ||
                        costRateSchedule[i].endDate.isBetween(periodStartDate, periodEndDate, "day", "[]")) {
                        costRateSchedule[i].costRate = costPeriod.costRate;
                    }
                }
                costRateSchedule[i].duration = weeklyHours;
            }

            // If the remaining hours exists update in the last week
            if (totalDuration > totalSpreadDuration) {
                const remaningHour = totalDuration - totalSpreadDuration;
                costRateSchedule[noOfWeeks - 1].duration = weeklyHours + remaningHour;
            }
        }

        return costRateSchedule;
    }


    /**
     * Get the blended cost rate for the given schedule
     * @param scheduleList
     * @param currentFinancialPlanCost
     * @param existingDuration
     * @param additionalDuration
     */
    private getBlendedRate(scheduleList: ICostRateSchedule[], existingDuration: number, additionalDuration: number, staffedCost: number, staffedHours: number): number {
        let totalAdditionalCost = 0;

        // used to calc blended cost rate, we use weekly period's cost rate
        for (const schedule of scheduleList) {
            const weeklyCost = schedule.costRate * schedule.duration;
            totalAdditionalCost = parseFloat(totalAdditionalCost.toFixed(2)) + parseFloat(weeklyCost.toFixed(2));
        }

        const totalCostIncludingCfpCost = totalAdditionalCost + staffedCost;
        const totalDurationIncludingCfpDuration = additionalDuration + existingDuration + staffedHours;
        if (totalDurationIncludingCfpDuration > 0) {
            const blendedCostRate = totalCostIncludingCfpCost / totalDurationIncludingCfpDuration;
            return blendedCostRate;
        } else {
            return 0;
        }
    }

}